<template>
  <div class="notice-wrap pt-nav">
    <Header title="MASK" :bgColor="'#000000'" :subTitleBgColor="'#000000'" :subTitle="$t('notice.title')">
    </Header>

    <div class="content-box">
      <div class="content-item" v-for="(item, index) in noticeList" :key="index" @click="handleNoticeDetail(item)">
        <div class="item-title">
          <div class="title">{{ item.title }}</div>
          <div class="date">{{ formatLocalTime(item.createdAt) }}</div>
        </div>
        <div class="item-text">
          <p>
            {{ item.detail }}
          </p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import { getNoticeList } from '@/service/base'

export default {
  name: 'Notice',
  components: { Header },
  data() {
    return {
      noticeList: []
    }
  },
  methods: {
    async init() {
      const resp = await getNoticeList()
      if (resp.code === 200) {
        this.noticeList = resp.data
      }
    },
    handleNoticeDetail(item) {
      this.$router.push({
        path: '/noticeDetail',
        query: {
          title: item.title,
          createdAt: item.createdAt,
          detail: item.detail
        }
      })
    },
    formatLocalTime(time) {
      return this.$moment(time).format('YYYY-MM-DD HH:mm')
    }

  },
  created() {
  },
  mounted() {
    this.init()
  },
}

</script>

<style scoped lang="scss">
.notice-wrap {
  background: #000;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .content-box {
    padding: 20px;
    background: #14151E;
    border-radius: 10px 10px 0 0;
    flex: 1;
    overflow-y: auto;

    .content-item {
      margin-bottom: 20px;
      border-bottom: 1px solid #3D3F51;

      .item-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        font-size: 14px;
        color: #fff;

        .title {
          font-weight: bold;
        }
      }

      .item-text {
        width: 100%;
        height: 3.5em;
        overflow: hidden;
        font-size: 13px;
        color: rgba(255, 255, 255, 0.85);
        margin-bottom: 20px;

        p {
          line-height: 1.2;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
